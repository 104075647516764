import { dateFormatter } from "utils/helperFunctions";
import Image from "next/image";
import { useRouter } from "next/router";
import { addDayInDate } from "utils/helperFunctions";
import dayjs from "dayjs";
import { encodeData } from "utils/encodeDecode";
import Link from "next/link";
import { isMobile } from "react-device-detect";

const destinations = [
  {
    id: 1,
    description: "Find hotels and flights at exciting prices and offers.",
    link: `search-results/U2FsdGVkX1%2F%2FeL6AlEAQt3AO96YXOlPY1n8m%2BSzL0NXJziud0omV51PQjHzK7prupU4mX2CWwTMZNvVE1EwJPSMhDKwaDDbokK4z5%2FzlwSeyM0FkSN6A2lod%2F1RYNd4OfO2jYteTd%2FPBbHITfwilrL8LdyIvaCXw97ObNV4E0bcfXfI80JVyfVTrzNRanH0FeBrg1gNkTSDO7KD%2Fe54NhqedWnAHFkDs1wKw1ziqDHq8y2NhpFqcRaFfuMzP8abnnaGA9z4vVlDd3PqdZwnWMkQF0DjNFBHN9pjpN6LPJI%2Bi02iteue8yCxMub%2BKCdj0QbZx%2FQJosHQem988BF%2BtgBnJJPRalg73H9kkV8PfqBsfkgyH0WfVqXdpNpDOwTyxYWhU1hdi2%2FiBiHmPnjIXIuEUjI5Kyi%2F8zWvkZEpkjeZHWX6zhbxyBRaDEqF6eyZzR1ebm7ZhVhIIhiP3q3fZ6W8fF4ENsCrjP3xHoB3LE%2FetrZPasq3ZMIHeoolJhi99K0OPWrztjk80jfG4i6rR7Oy28uteiFL%2FoxJlxDukeYIElpYkPKX6mlprjR5E1N86L9MkilzZyn6FUMMD8C%2Fems1L1F4R4gH4Ay%2FzKmtTLluTZd4YcHUcs8sAcX4H8KE3QAxOYbLh2Q02iGCFY8RZo31Y0LW716wpS4Xz07PMpuqI0Df61VgwcCz%2BniWyhMQAJB%2BP2l1DdbKAHL8%2FasHzD05hZsl20yKhZyWMXLmfb5JDFbDEr9MwsLRQEeYlieFvsI7xG3jEaJMlMreEZMyjLk3s2efdEQZ3Kndc63D2aTe34uPV13S%2F3cMCz83ryTbvtm8Gc1jyhSUJBeKAuAhMmJ1BAqF9cmXkNnhYXizJ3W3HWbDDTcFj1gx7kn87tKf4RRjOiGArHLw2Js3f9xBwUvgN1Tk9I%2FgdZrCfcgfjcsnOEWJMYyLbxoUyoLqj4nNT`,
    country: "Italy",
    city: "Rome",
    image: "/image/italy.webp",
    data: {
      hotel: "Rome,  Italy",
      hotel_id: "5b69ad7f7115d74f300a15d5",
      hotelDetails: {
        _id: "5b69ad7f7115d74f300a15d5",
        k: "100117",
        z: "2124",
        d: "Rome,  Italy",
        country_code: "IT",
        city: "Rome",
        state: "",
      },
    },
  },
  {
    id: 2,
    description: "Find hotels and flights at exciting prices and offers.",
    link: `search-results/U2FsdGVkX19wOApVeoO4qoLqxVPm6CenkVXS08JrRkj9RuluhiIegI1UUZjMstJzuFoISAHnSXh52R3Xnw4is8kyHC9fimnZTAY9TlCteh%2BOQtVjHgi99CjnUcZ4dNeMQXq%2FkIxSiX2IbYAW6MsFCmJFquwSs5rAHck30mJjGqicwsrLcZZxKAcXXKjjKAR8gn3V0Ny5FkhFPJh0%2Bj%2BAE04KxAQJZdK5bOKmHmArkJ4ly%2FViswtch7RkIsBVHlmnUqAahBvde%2Bs%2Faut746NgUhYVQEydYFI%2BvjfTCPmLtT7UYWhRCLOg837Jj9ls%2BksRNMJ3os3rnF%2FYGYaytqH2tHLBbMCfREnHSVpLtmHbZCGbLvd%2FV6Bj3FWEZlq9L1lyh2EbZX3b3key9lTsxMnLCTMQ3SXSlId%2BcDmJzyBxS%2F5q7meVLTJJLW1WrYWAxJayS032xrCDwzLD%2F%2BKMG5auiO7wGEvYxgt1rxiRSvW030dOVow1hK00DrVV9JtjdgoyvjG493E3VOGjqbm7WOPGGXCyXOaggIQgnseIu7nWH6lxyKlsZaFwPSOog3%2Bk5Ozph%2FKTTaRMfmFyJPRAyUUAN1xakSxqLBzS5wHy67Lh0qaPHlDeLdL2xgw50ECzoDz17h7DdILeVholIHZ25sNCjAPkaDRFlfuWCi0UNxFNKHlu%2BxbBMrwEpMY6znMLMilQLrA49iNS9%2B%2Bu0lLm3hc0yqaMfnIOmsjIWIkgryIk8mcCzuMQeMI4vTCPIrNCBn1RMWzFJ191L6wPiWg1wCgqm0sdQKTv4SVYyVbWhQe7V4mddng2Ck4%2F1fJCTHiNEauOaO5YaR9O9gtVymMIEVcVoLazOOeH1B9gJGjcGHQekxWKIVLTJo0NIZty%2Fc8EgwgSeT0MjBdexia3DuwvvVFsSP3C8uAgaimEMb0TfukEheKeGxVUzZH1kVvYFWLrKDOS`,
    country: "France",
    city: "Paris",
    image: "/image/paris.webp",
    data: {
      hotel: "Paris,  France",
      hotel_id: "5b69ad7f7115d74f300a4c9d",
      hotelDetails: {
        _id: "5b69ad7f7115d74f300a4c9d",
        k: "100115",
        z: "2569",
        d: "Paris,  France",
        country_code: "FR",
        city: "Paris",
        state: "",
      },
    },
  },
  {
    id: 3,
    description: "Find hotels and flights at exciting prices and offers.",
    link: `search-results/U2FsdGVkX1%2F%2BSFc9vhmVqaF4XIkk9JB1FRw0pieTKIUHYo5jCqlOehU0gdUeOJOBrhFgF7HiRikAbf8Y05vWu30TEqrl5X35oOn4tSSKNr0j5aoZllD%2B3BY6pocUdpiHoJUskUefqmrh0VLPt%2Ff0pFn4jc6LlSUvO4IvEepVfc%2B5rBexZhEHZOLj%2Fr422AHPkUVf%2Fzy0Twwkya3uBjMLQnswX%2FXJN3hU%2BGvZuZZw6mYFX%2FDy3s0EcTfefDcWEGdTOxhPLaPtkSBIrWWtH15rZN%2BoBmkOElil414fGou2vls9apKG4A%2FdiTNm3iRBRMcMnCsczlrhErhjoRVW7ZdDFh1WpJc7zU8ALDlYQK16XfxBCy7KICYF1E0LypAzV4lDXkazU4oQjGBmEbAW934RO5NaOckZwmg%2F%2B8XB%2FwWD7uu0tle6zcLopCo1JUVPAGD4BfE%2F0xOnRjrNnRIJeOi%2FoL8nAIyQdt124afZo1eD1%2F%2B0MBdBCdV51jG376KONQ%2BtIWnBQ4g5zUXGPoMa0yuPdg%2B%2Bp1nWc1jjbi9kV%2FyDAEpLRyvftd6Lt2NU8PGA1X%2BhumkNpHO%2BbZXhByrv4Zf3ougszyDRrTG4qRQp7QVN9pi8%2FtoS93ZEOnxRebT0jsu2SiyUokq7N0aIqEIHJNPynJBVwdFCvL5odAJfAH%2BBDn3YQaIYOf7HkPXJfqQjgamd2GDLQsX57IMDw8r%2FLA9CqdHz4%2BuuLBYXxCFgWpuzIgeJJxSn7oRkiLhOVn73YeBca8y5jZ9EV72ECMtjp3juskRp%2BblywNf%2BrQql6LHXdJv2XBQeyiBiwj5XLu4OWJOHaO8Aur9Wyji%2Bci4%2BOXCIbpIbGU3na72067jf29o%2F8xwPh7PVc%2Bg%2FMp6jGHFXqSyziy7NqxofvQ3bxrxer0lXa11PIKfxheYiONbvmXQvgqw9abdSqPL4dARbWJniqbOP5J9UWr9v7VmApNTIiA5xv5Nctrx7wAOf7SFRVTpOro8%3D`,
    country: "Malaysia",
    city: "Kuala Lumpur",
    image: "/image/malaysia.webp",
    data: {
      hotel: "Kuala Lumpur,  Malaysia",
      hotel_id: "5b69ad7e7115d74f3009c875",
      hotelDetails: {
        _id: "5b69ad7e7115d74f3009c875",
        k: "100079",
        z: "501",
        d: "Kuala Lumpur,  Malaysia",
        country_code: "MY",
        city: "Kuala Lumpur",
        state: "",
      },
    },
  },
  {
    id: 4,
    description: "Find hotels and flights at exciting prices and offers.",
    link: `search-results/U2FsdGVkX1%2B8IpSQgmIWzTI%2FH9DTZVrlrtBYlV5J%2FIA9DjGat7sPqhUTALeRqvbTOtAvWHtKiGiKnNsLA4T5ytXagr%2F4lqGA1yegX39KkwWzLPdBybLJj%2FscJpHekajZkwcWRsL2xjWRhN3CANgwWG6oCzGhFoO9DJ%2FiO33QHoILTIWg2ZmhmODZ6snrIyvMkKICX6rgROOKRb0tG%2FanjrzYUG13Jtx8Cc5whImYJ5SYi7Xr%2F3HzFGu6ypC3h25yYzXgbMxUQ7P6PbDHv9ILwMkMI9iz5mlzqEIcXT3g6vKlEr2T0DV25cCmoFmFaY17BFgZGg3pH8cq1uX1BMRX9743pPUusP857mbYWtW9ZqUSMri5qqB8%2FpkHk%2BnkSCuXvsGphD74hX0xL%2FYB4jZiVONM1EJEouR6S%2FW9IlFz5iJz0t9iGzLrWTerhJUq6Xi2uXQs66oZScg7lPXMJdtlB%2FDo6dDJSw8eQCWm5reAYjXK4Dgq8jjAebVZSR97ay%2BSR9Pz62wJEKxtFdbGNz2wgRsIz2I7o8PXbh4QZ67Ru2kZ0AIIQy16o5H9JmKb6YJSHLvVsZyL5K%2Bl35v82FHObJT2sH7VnYokmzHCc%2F8aVtW2HxahFjKSzSiqwrpPvS23kWIGi916zTMQWNotfj7b%2F3ebWQfevsliiIcJcYI4LJQaRhEfOlr%2FkE%2BFc6PwImXhzSnAtjT89lK38YWb5vCQCbPV16ZnjQCQEnH5pV5IQozjvodM4jJOYED4On6Xs83Eu%2BFo6UAcu3xUZ3cJ%2BSAzJh%2FHmxrCjGOrKgHKyVUvHUvD42ngkTRa9fLkHOIce7BR68Wu0JGARf2qwvubtyCf1qa1E5qGcwTATX2ixPB9OWoKxLF14985%2FySdaZzq2xZd8P9AcNdcwMzt1s%2BtsOdowad0LTde1aedYk%2BWAQusoFI9o8LQW5HEe6NsP2j%2FfUGi`,
    country: "Maldives",
    city: "Male",
    image: "/image/maldives.webp",
    data: {
      hotel: "Istanbul,  Turkey",
      hotel_id: "5b69ad7e7115d74f3009b2e0",
      hotelDetails: {
        _id: "5b69ad7e7115d74f3009b2e0",
        k: "100990",
        z: "1482",
        d: "Istanbul,  Turkey",
        country_code: "TR",
        city: "Istanbul",
        state: "",
      },
    },
  },
  {
    id: 5,
    description: "Find hotels and flights at exciting prices and offers.",
    link: `search-results/U2FsdGVkX194PB91T3%2Fj3JArbYOjxp%2FRx5ZFNxo2meHr7vSaI50iLSjCN3e2lw4nWF6l9ueZ0kHBJHfRC3YuVOjXRjYLCRUfpNWuZ8gi4gVX0AEKBVd1B8brqSNHh%2BAtB3hBlV6yuUVGiaWwIbcmdaUMU6uNVI2AhO2PFvt%2BT8x6iASjKLXX9t1UtE%2FDfCVYT4WrkEkbCUxAsoi5cyDULjGUWbCvbdU2kzPl0coivJIs9oK7%2BPD2yL5dEifon%2FtAtZAdPD%2Fu9XqMRemCTo4Zt6RZhFc6CHXS5gZHtyiVR6nPH5nGAXVq9aFTNYWCE92Eu9FW2tddZX3Yv%2FHAog%2F1%2FNnzheUEuOp9NOTzirixFZ60caS%2FpuAOvb2nXc7GRy7gnUBGA7cyEVzpKpmevpDcMo%2FSRdaMzrktsbOGt2OjE1hbyt65Bvnoj6YvfdcbBhJPPDuS6CZbUMmKZ7fusROS8Qw60zMUcYCP9nyCD5r%2FZhCKFnJOulDdlWnTm0lnCel5TuwaT0pnrAOxVlm3Nwi6%2FiA894jJ02WrI4ZT8%2FwvJJOV3%2F1HPLq1u7nKGzUu1%2FUx76WX08BgiVZiKemOV9ltP5p%2BKHa1kuJegyuZkPAQo%2B2ASEdZkhjyUVec%2Boa34nay2YYkX%2F27pA5J%2FUytgp77Z%2BTKxPfZs9xJoK8inDHgR97UxsLyE8yBO%2FlkxCvjEk1W1rahIlxBNLZOa0e0ULhRAvHghmZHbp7KX5rfMn2ZfCkaMIPi9ZwEgiQBHXfZXE6iWp55fg%2BnAcoAo27fwYBn8goyXIgLBlWcZ3MPUiUwJA%2BrGdj9JKJeADMT8TXXaQ3SRWtR8Kh3ra4Zn9M30rxSBu0QcseeZfQUfR9kO7iFr5yCvzBzfcceqARpFrR1jEafFxEUG5buPOGIX5zPRvX9fzd3NOjeWDzfQrDgcu7tYdUWwOe8t8h%2BkWH6E5FzCaX7yl5eTLqVg0XLLhrKS4K28g%3D%3D`,
    country: "Turkey",
    city: "Istanbul",
    image: "/image/turkey2.webp",
    data: {
      hotel: "Istanbul,  Turkey",
      hotel_id: "5b69ad7e7115d74f3009b2e0",
      hotelDetails: {
        _id: "5b69ad7e7115d74f3009b2e0",
        k: "100990",
        z: "1482",
        d: "Istanbul,  Turkey",
        country_code: "TR",
        city: "Istanbul",
        state: "",
      },
    },
  },
  {
    id: 6,
    description: "Find hotels and flights at exciting prices and offers.",
    link: `search-results/U2FsdGVkX18EDdjR%2BPvLpPfE4jeqoaLTadD9fQWtLL4swEtcKqmiwkGUXCtfDF%2FhxzjyN0Vzshao%2FOFJY3wGBteCVx6IGb75cEbU8xWKQt0tsdDLIfNPy3Kux5m42wW7UNTMHtCFkzY2Hlo135yLWuQ6sRYHRojZA7gaXnJfbVvICgWpTS9kR4JrUIHhU5ZZmqVHMOjH0VUFY3pTzDzcUQHeYYFXzT26yWP9pkzOtuc5ZzrLPV2A8KO91BqrvUO4ZP%2FeQ%2FPEfNWXAJ0r9Bqo4LYiZ2PJzZ%2FzmMcYoTJvtFCqe1QvXX8ztZXgiWGvJQloHUW49uPqAkOcY%2Fq1WM%2BtzCzc%2BVA4wsoHc3c%2FilkbGegvMlTHEPlFtXT8uhQ6LyKmnddUEE%2BJXBnEFIIIpEaORl0YxRHCUPKh2zqG%2Bxm6wZegyzTjn9Kp5lJE7Yhsh%2BOANF9Vkn8Vm66CVLdowO2Ndz6JVTSnLIGjbavEVBLLb%2BCfuA7JMlHKxGe7Em0pEm%2F4sfaLLGlh%2BDUCQ4gwHp9kNUwHnZ3KTVIlkPKUg066VacYYRf%2B%2BQMK%2F5NyHTSqJXr%2FZVbsA5T0ttTmM0DCA4SPNxm%2FD5jepIib4olGbafC%2FZ4ZWTi5Ns%2FU3gwStgjtVaPtyFOTITe8B7SJf%2BWlXp74BTiP6LIt%2FeWG%2BJGwErbJqK1HEvXFbWEpxfjHcaUdBlH0Zw1W2IEdDechh89wXMBxNofu2Hgj7n5ofvwLtA%2Bf4NPYflyx1Bnh%2FoONk8S9Mq1a9Q7nMAunIOVoeMnNmoqc1HaIIcksFWQt3ImoEjHuts6ZDF2pagPAxVJRFlLORZkaqug98BLlFWXjHI52lV9Z9aPe7FxhydFFTQPWOdE%2Fj%2FrF2tWnR52El1orq07XgEH%2FF0Spg7ws34hLN5dIZpnaA2ggSqL9wHcGhGI5jOaXC9KMfFK42wma6DdBmvCPZKqSyCYiyZ5ScfCo9YITLYpRbYSOjk1Liv6pmUS57dBXIED5798xy0saRAMSjE1fg3w3zjcEsidtLnVlQd%2B6jOrxkQ%3D%3D`,
    country: "India",
    city: "Delhi",
    image: "/image/india.webp",
    data: {
      hotel: "Delhi, Delhi,  India",
      hotel_id: "",
      hotelDetails: {
        _id: "",
        k: "35485",
        z: "854",
        d: "Delhi, Delhi,  India",
        country_code: "IN",
        city: "Delhi",
        state: "Delhi",
      },
    },
  },
];

const queryData = {
  flightData: {
    to: "",
    from: "",
    adults: 1,
    infants: 0,
    children: 0,
    cabin_type: "E",
    oneWay: false,
    totalPassengers: 9,
    onward: new Date(),
    showToList: false,
    showFromList: false,
    "return-date": addDayInDate(1, new Date()),
  },
  selected: "hotel",
  checkin: new Date(),
  guest: [
    {
      adult: 2,
      childAges: [],
      rooms: 1,
      children: 0,
    },
  ],
  deafultSelectedRoom: 0,
  showFromFlightList: false,
  showToFlightList: false,
  showGuestPopup: false,
  checkout: addDayInDate(1, new Date()),
};

const PopularDestination = () => {
  const router: any = useRouter();
  const target = isMobile ? "_self" : " _blank";

  return (
    <section className="popular_destinations bg-white dark:bg-dark-bg-primary mt-[0px]  ">
      <div className="container mx-auto">
        <div className="mb-8 ">
          <h3 className="popular_header capitalize font-bold text-xl sm:text-3xl text-[#1a1a1a] dark:text-dark-text-primary m-0 ">
            Popular Destinations
          </h3>
          <p className="m-0 font-normal dark:text-dark-text-secondary ">
            See what's popular with other travellers
          </p>
        </div>
        <div className="overflow-hidden grid-box ">
          {destinations.map((eachDestination: any, eachInd) => (
            <div className="destination " key={eachInd + 1 + ""}>
              <Link legacyBehavior href={eachDestination.link || "#"}>
                <a target={target}>
                  <div className="inner">
                    <div className="image_container">
                      <Image
                        src={eachDestination.image}
                        objectFit="contain"
                        alt=""
                        width={100}
                        height={100}
                        unoptimized={true}
                        className="w-full h-full dest_image undefined "
                      />
                    </div>
                    <div className="flex flex-col text-area col_span ">
                      <span className="my-2 text-sm pre_title lg:text-lg">
                        {eachDestination.country}
                      </span>
                    </div>

                    <div
                      className="title destination_info  lg:grid hidden bg-[rgba(0,0,0,0.2)] text-white   absolute   place-items-center inset-0 h-full   rounded-[4px]
                         backdrop-blur-[5px]"
                    >
                      <div className="text-center ">
                        <p className="mb-0 text-xs text-white uppercase">
                          {eachDestination.country}
                        </p>
                        <p className="m-0 text-2xl font-medium uppercase cursor-pointer">
                          {eachDestination.city}
                        </p>
                        {/* <p className="mb-2 text-sm text-gray-100 ">
                          {eachDestination.description}
                        </p> */}
                      </div>

                      {/* <button
                        onClick={() =>
                          router.push({
                            pathname: "/search-results/[slug]",
                            query: {
                              slug: encodeData({
                                ...queryData,
                                ...eachDestination.data,
                              }),
                            },
                          })
                        }
                        className="  text-white text-sm m-0 !w-[fit-content]"
                      >
                        Explore more
                      </button> */}
                    </div>
                  </div>
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PopularDestination;
